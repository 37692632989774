<template>
  <v-row>
    <v-col cols="12">
      <v-form ref="formNumerations">
      <v-progress-linear
        v-if="isLoading"
        indeterminate
      />
        <base-card>
          <v-card-title>Resoluciones de facturación electrónica</v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                sm="4"
              >
                <v-select
                  ref="cliente"
                  v-model="dataNumerations.IdCompania"
                  :items="detailClients"
                  item-text="text"
                  item-value="IdCompania"
                  label="Compañia"
                  prepend-inner-icon="mdi-sitemap"
                  :rules="formRules"
                  :error-messages="errorMessages"
                  filter
                  search-input
                  required
                >
                  <template v-slot:prepend-item v-if="isAdmin">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-text-field
                            class="search-field"
                            v-model="search"
                            placeholder="Buscar"
                            prepend-inner-icon="mdi-magnify"
                            hide-details
                          ></v-text-field>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-select>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-btn
                  class="ma-2"
                  style="background-color:rgb(204 33 40);color:white !important"
                  v-if="isAdmin"
                  @click="validateClientToGetNumerations"
                >
                  Buscar
                </v-btn>
                <v-btn
                  class="ma-2"
                  color="GRAY"
                  @click="validateClientToUpdateNumerations"
                >
                  Actualizar
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-alert
                v-show="successUpdateAlert"
                :type="updateAlertType"
                text
              >
                {{ msgUpdateAlert }}
              </v-alert>
              <v-alert
                v-show="successAlert"
                v-if="isAdmin"
                :type="alertType"
                text
              >
                {{ msgAlert }}
              </v-alert>
            </v-row>
          </v-card-text>
        </base-card>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'NumerationsManager',
  data() {
    return {
      dataNumerations: {
        IdCompania: '',
      },
      search: '',
      isAdmin: true,
      isLoading: false,
      errorMessages: '',
      formToGetHasErrors: null,
      formToUpdateHasErrors: null,
      alertType: 'success',
      successAlert: false,
      msgAlert: '',
      updateAlertType: 'success',
      successUpdateAlert: false,
      msgUpdateAlert: '',
      detailsClients: {},
      formRules: [
        (v) => !!v || 'Este campo es requerido',
      ],

    }
  },
  computed: {
    detailClients() {
      if (this.isAdmin) {
        let spamCompany = {
          "IdCompania": -1,
          "NombreCompania": "TODAS LAS COMPAÑIAS"
        }
        let activeCompanies = (this.$store.state.numerations.listClients.Mensaje || [])
          .filter(compania => compania.Activo === 1)
          .filter(compania =>
            compania.NombreCompania.toLowerCase().includes(this.search.toLowerCase())
          )
          .sort((a, b) => {
            if (a.NombreCompania < b.NombreCompania) return -1;
            if (a.NombreCompania > b.NombreCompania) return 1;
            return 0;
          })
	.map(compania => ({
        ...compania,
        text: `[${compania.IdCompania}] ${compania.NombreCompania}`  // Cambiar formato a [Id] Nombre
      }));
        return activeCompanies
      }
      else {
        return (this.$store.state.numerations.listClients.Mensaje || []).filter(compania => compania.IdCompania === JSON.parse(atob(localStorage.getItem('uid'))).idcompania)
      }
    },
    statusResponseNumerations() {
      return this.$store.state.numerations.statusResponse
    },
    statusResponseUpdateNumerations() {
      return this.$store.state.numerations.updateStatusResponse
    },
    statusResponseNumerationsMsg () {
      return this.$store.state.numerations.statusResponseMsg
    },
    statusResponseUpdateNumerationsMsg () {
      return this.$store.state.numerations.statusResponseUpdateMsg

    },
  },
  watch: {
    formToGetHasErrors() {
      if (this.formToGetHasErrors === false) {
        this.getNumerations()
      }
    },
    formToUpdateHasErrors() {
      if (this.formToUpdateHasErrors === false) {
        this.updateNumeration()
      }
    },
    statusResponseNumerations() {
      if (this.statusResponseNumerations === false) {
        this.generateAlert('success', this.statusResponseNumerationsMsg)
        this.formToGetHasErrors = true
        this.$store.dispatch('numerations/setStatusResponse', null)
      } else if(this.statusResponseNumerations === true) {
        this.$store.dispatch('numerations/setStatusResponse', null)
        this.generateAlert('error', this.statusResponseNumerationsMsg)
        this.formToGetHasErrors = true
      }
    },
    statusResponseUpdateNumerations() {
      if (this.statusResponseUpdateNumerations === false) {
        this.generateUpdateAlert('success', this.statusResponseUpdateNumerationsMsg)
        this.formToUpdateHasErrors = true
        this.$store.dispatch('numerations/setStatusUpdateResponse', null)
      } else if (this.statusResponseUpdateNumerations === true) {
        this.$store.dispatch('numerations/setStatusUpdateResponse', null)
        this.generateUpdateAlert('error', this.statusResponseUpdateNumerationsMsg)
        this.formToUpdateHasErrors = true
      }
    },
  },
  created () {
    this.isAdmin = JSON.parse(atob(localStorage.getItem('uid'))).rol === 'Administrador'
    this.getListClients()
    if(!this.isAdmin) {
      this.dataNumerations.IdCompania = JSON.parse(atob(localStorage.getItem('uid'))).idcompania
      this.getNumerations()
    }
  },
  methods: {
    async getListClients () {
      await this.$store.dispatch('numerations/getListClients')
    },
    generateAlert (typeAlert, msj) {
      this.successAlert = !this.successUpdateAlert
      this.msgAlert = msj
      this.alertType = typeAlert
      this.isLoading = false
      setTimeout(() => {
        this.successAlert = false
      }, 3000)
    },
    generateUpdateAlert (typeAlert, msj) {
      this.successUpdateAlert = true
      this.msgUpdateAlert = msj
      this.updateAlertType = typeAlert
      this.isLoading = false
      if(typeAlert == 'success') {
        this.getNumerations()
        setTimeout(() => {
          this.successUpdateAlert = false
        }, 3000)
      } else {
        setTimeout(() => {
          this.successUpdateAlert = false
        }, 5000)
      }
    },
    validateClientToGetNumerations () {
      this.formToGetHasErrors = !this.$refs.formNumerations.validate()
    },
    validateClientToUpdateNumerations () {
      this.formToUpdateHasErrors = !this.$refs.formNumerations.validate()
    },
    getNumerations() {
      this.isLoading = true
      this.$store.dispatch('numerations/getNumerations', {
        ...this.dataNumerations
      })
    },
    updateNumeration() {
      this.isLoading = true
      this.$store.dispatch('numerations/updateNumerations', {
        ...this.dataNumerations
      })
    },
  }
}

</script>

<style scoped>
.search-field {
  border-bottom: 1px solid #F2F4F4;
}
</style>
